import { insurance_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { useQuery } from 'react-query';
import {
  insurancePolicyKeys,
  parseInsurancePolicyKey,
} from '../cache-keys/insurancePolicyKeys';
import { queryFunction } from '../query-function';

export function useInsurancePolicy(insurancePolicyID?: string) {
  const api = useAPI();
  const cacheKey = insurancePolicyKeys.root({
    insurancePolicyID: insurancePolicyID ?? '',
  });

  return useQuery(
    cacheKey,
    queryFunction(
      insurance_api.getInsurancePolicy(api),
      ({ queryKey }) => parseInsurancePolicyKey(queryKey).insurancePolicyID
    ),
    {
      enabled: !!api.token && !!insurancePolicyID,
    }
  );
}
